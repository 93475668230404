//其他入库申请
<template>
  <Page ref="page" v-loading="loading" :showReturn="!isCreate">
    <div>
      <el-form ref="form" label-width="140px" :model="params" :rules="rules">
        <div class="cm-flex-line">
          <el-form-item label="入库日期：" prop="orderTime">
            <el-date-picker
              style="width: 100%"
              v-model="params.orderTime"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="{
                disabledDate(time) {
                  return time.getTime() > Date.now()
                }
              }"
            />
          </el-form-item>
          <el-form-item label="入库仓库：" prop="inWarehouseCode">
            <SelectWarehouse
              :code.sync="params.inWarehouseCode"
              :name.sync="params.inWarehouseName"
            />
          </el-form-item>
          <el-form-item label="入库类别：" prop="storageCode">
            <SelectWarehouseType
              :code.sync="params.storageCode"
              :name.sync="params.storageName"
              :type="1"
            />
          </el-form-item>
        </div>
        <el-form-item label="备注：" prop="remark">
          <el-input
            v-model="params.remark"
            placeholder="请输入备注"
            type="textarea"
            maxlength="255"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="fileList" label="附件：">
          <UploadFile
            ref="UploadFile"
            v-model="params.fileList"
            :showAccept="false"
            :limit="3"
            multiple
            :preAppendixAddr="params && params.attachmentUrl"
          />
        </el-form-item>
        <el-divider content-position="left">入库物品清单</el-divider>
        <div style="display: flex; justify-content: end; margin-bottom: 10px">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="addEvent()"
          >
            添加
          </el-button>
        </div>
        <el-table :data="params.detailUpdateInputs">
          <el-table-column header-align="center" label="存货编码">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.code'"
                :rules="rules.code"
              >
                <SelectGoodsCode
                  :code.sync="params.detailUpdateInputs[$index].code"
                  :name.sync="params.detailUpdateInputs[$index].name"
				  :bserial.sync="params.detailUpdateInputs[$index].bserial"
				  @change="
				    data => {
				      if (data.bserial) {
				        params.detailUpdateInputs[$index].num = 1
				      }
				    }
				  "
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="存货名称">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.name'"
                :rules="rules.name"
              >
                <SelectGoodsName
                  :code.sync="params.detailUpdateInputs[$index].code"
                  :name.sync="params.detailUpdateInputs[$index].name"
				  :bserial.sync="params.detailUpdateInputs[$index].bserial"
				  @change="
				    data => {
				      if (data.bserial) {
				        params.detailUpdateInputs[$index].num = 1
				      }
				    }
				  "
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="货位">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.positionCode'"
                :rules="rules.positionCode"
              >
                <SelectGoodsLocation
                  type="in"
                  ref="SelectGoodsLocation"
                  :code.sync="params.detailUpdateInputs[$index].positionCode"
                  :name.sync="params.detailUpdateInputs[$index].positionName"
                  :params="{
                    warehouseCode: params.inWarehouseCode
                  }"
                />
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="数量">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.num'"
                :rules="rules.num"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].num"
                  placeholder="请输入数量"
				  :readonly="params.detailUpdateInputs[$index].bserial"
                  @input="
                    val =>
                      (params.detailUpdateInputs[$index].num =
                        $format.fmtNumber(val))
                  "
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="序列号">
            <template slot-scope="{ row, $index }">
              <el-form-item 
				label="" 
				label-width="0"
				:prop="'detailUpdateInputs.' + $index + '.sn'"
				:rules="params.detailUpdateInputs[$index].bserial ? rules.sn : ''"
			  >
                <el-input
                  v-model="params.detailUpdateInputs[$index].sn"
                  maxlength="30"
                  placeholder="请输入序列号"
                  oninput="value=value.replace(/[\u4e00-\u9fa5|\s]/g, '')"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="单价">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.amount'"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].amount"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label="金额">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.totalAmount'"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].totalAmount"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column header-align="center" label="备注">
            <template slot-scope="{ row, $index }">
              <el-form-item
                label=""
                label-width="0"
                :prop="'detailUpdateInputs.' + $index + '.remark'"
              >
                <el-input
                  v-model="params.detailUpdateInputs[$index].remark"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column header-align="center" label=" ">
            <template slot-scope="{ row, $index }">
              <el-form-item label="" label-width="0">
                <el-button type="text" @click="deleteEvent($index)">
                  删除
                </el-button>
              </el-form-item>
            </template>
          </el-table-column>
          <template slot="empty">
            <div>暂无数据，请添加</div>
          </template>
        </el-table>
      </el-form>
    </div>
    <FlowableStep
      orderType="6"
      :variables="variables"
      @initNode="setNodes"
      @refresh="setAuditors"
    ></FlowableStep>
    <template slot="bottom">
      <el-button
        type="primary"
        @click="submitEvent(1)"
        style="margin-right: 10px"
      >
        提交申请
      </el-button>
      <el-button type="info" @click="submitEvent(2)">保存草稿</el-button>
    </template>
  </Page>
</template>

<script>
import FlowableStep from '@/components/FlowableStep'
import applyMixins from '../../mixins/applyMixins'
export default {
  mixins: [applyMixins],
  components: { FlowableStep },
  data() {
    return {
      rules: {
        orderTime: [{ required: true, message: '请选择入库日期' }],
        inWarehouseCode: [{ required: true, message: '请选择入库仓库' }],
        storageCode: [{ required: true, message: '请选择入库类别' }],
        num: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value || Number(value) === 0) {
                callback('请输入非0整数')
              } else {
                callback()
              }
            }
          }
        ],
        code: [{ required: true, message: '请选择' }],
        name: [{ required: true, message: '请选择' }],
        positionCode: [
          {
            required: false,
            validator: (rule, value, callback) => {
              try {
                const $elem = this.$refs['SelectGoodsLocation']
                const list = $elem?.list || []
                if (list.length > 0 && !value) {
                  callback('请选择货位')
                } else {
                  callback()
                }
              } catch (e) {
                console.log(e)
              }
            }
          }
        ],
		sn: [{ required: true, message: '请输入序列号' }]
      },
      params: {
        detailUpdateInputs: [],
        auditors: {}
      },
      nodes: [],
      variables: {
        WAREHOUSE_IN: '' // 条件参数值
      }
    }
  },
  computed: {
    isCreate() {
      return this.$route.query.edit != 1
    }
  },
  mounted() {
    this.loading = true
    this.init()
    this.loading = false
  },
  methods: {
    setNodes(nodes) {
      this.nodes = nodes
    },
    setAuditors(auditors) {
      this.params.auditors = auditors
    },
    async init() {
      try {
        const { orderCode } = this.$route.query
        const res = await this.$api.apply.storageOrderInfo({ orderCode })
        if (res) {
          let { orderResult, detailResults } = res
          detailResults = detailResults.map(a => {
            return {
              ...a,
              amount: a.amount / 100,
              totalAmount: a.totalAmount / 100
            }
          })
          let params = {
            ...orderResult,
            detailUpdateInputs: detailResults,
            auditors: {}
          }
          this.params = params
        }
      } catch (err) {
        console.log(err)
      }
    },
    addEvent() {
      this.params.detailUpdateInputs.push({
        num: '',
        code: '',
        name: '',
        amount: '',
        totalAmount: '',
        remark: '',
        positionCode: '',
        positionName: ''
      })
    },
    deleteEvent(index) {
      this.params.detailUpdateInputs = this.params.detailUpdateInputs.filter(
        (a, i) => i !== index
      )
    },
    submitEvent(submit) {
      if (this.loading) return

      let params = this.$utils.deepClone(this.params)
      if (params.orderTime) {
        params.orderTime = params.orderTime + ' 00:00:00'
      }
      if (params.fileList?.length > 0) {
        params.attachmentUrl = params.fileList
          .map(a => {
            return `${a.url}?fileName=${a.name}`
          })
          .join(',')
        delete params.fileList
      }
      let detailUpdateInputs = params.detailUpdateInputs
      detailUpdateInputs = detailUpdateInputs.map(a => {
        a.amount = a.amount > 0 ? Number(a.amount) * 100 : a.amount //元转分
        a.totalAmount = a.totalAmount > 0 ? Number(a.totalAmount) * 100 : a.totalAmount //元转分
        return { ...a }
      })
      delete params.detailUpdateInputs
      let newParams = {
        submit: submit == 1,
        detailUpdateInputs,
        updateInput: params
      }
      if (submit != 1) {
        try {
          this.loading = true
          this.$api.apply.storageOrderSave(newParams).then(result => {
            this.$message.success('草稿保存成功')
          })
        } finally {
          this.loading = false
        }
      } else {
        if (this.nodes.length) {
          let flag = true
          for (let i = 0; i < this.nodes.length; i++) {
            if (
              this.nodes[i].assigneeType == 'SELF_SELECT' &&
              this.nodes[i].userType == 2 &&
              this.nodes[i].users.length == 0 &&
              !this.params?.auditors[this.nodes[i].id]?.length
            ) {
              flag = false
              return this.$message.warning('请选择审批人后提交')
            }
          }
          console.log('flag', flag)
          if (!flag) return
        }
        this.$refs['form'].validate(async valid => {
          if (valid) {
            try {
              if (this.params.detailUpdateInputs.length === 0) {
                this.$message.warning('请至少添加一条物品清单')
                return
              }
              this.loading = true
              clearInterval(this.timeSaveFlag)
              await this.$api.apply.storageOrderSave(newParams)
              //提交成功
              this.submitSuccess('/#/storageOrderApply')
            } finally {
              this.loading = false
            }
          }
        })
      }
    }
  }
}
</script>
